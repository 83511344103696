'use strict';

$(document).ready(function() {
  $('.expedition__btn, .map__btn-video').magnificPopup({
    disableOn: true,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });

  $('.header__nav-toggle').click(function(){
    $('.header__nav').toggleClass('header__nav--open');
    $('.header').toggleClass('header--open');
  });

  $('#slider1').magnificPopup({
    type: 'inline',
    fixedContentPos: false,
    fixedBgPos: true,
    overflowY: 'auto',
    closeBtnInside: true,
    preloader: false,
    removalDelay: 300,
    items: [
      {
        src: 'img/slider1_1.jpg'
      },
      {
        src: 'img/slider1_2.jpg'
      },
      {
        src: 'img/slider1_3.jpg'
      },
      {
        src: 'img/slider1_4.jpg'
      }
    ],
    gallery: {
      enabled: true
    },
    type: 'image' // this is a default type
  });

  $('#slider2').magnificPopup({
    type: 'inline',
    fixedContentPos: false,
    fixedBgPos: true,
    overflowY: 'auto',
    closeBtnInside: true,
    preloader: false,
    removalDelay: 300,
    items: [
      {
        src: 'img/slider2_1.jpg'
      },
      {
        src: 'img/slider2_2.jpg'
      },
      {
        src: 'img/slider2_3.jpg'
      }
    ],
    gallery: {
      enabled: true
    },
    type: 'image' // this is a default type
  });
});